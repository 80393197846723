<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.siteDetails.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <site-details-card
          :loading="fetching"
          :instance="instance"
          @update:inputValue="updateSiteName"
          ref="siteDetailsCard"
        />
        <v-col cols="12" md="6" sm="12">
          <v-card elevation="4">
            <instance-card-img-not-available
              v-if="!instance.screenshot_url"
              :instance="instance"
            />
            <v-img :src="instance.screenshot_url" width="100%" height="364px" v-else>
              <template v-slot:placeholder>
                <v-skeleton-loader type="image" height="100%" />
              </template>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12">
          <installation-details-card
            :loading="fetchingInstallDetails"
            :installationDetails="installationDetails"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import SiteDetailsCard from "../../../components/cards/details/SiteDetailsCard";
import InstallationDetailsCard from "../../../components/cards/details/InstallationDetailsCard.vue";
import InstanceCardImgNotAvailable from "../../../components/instances/InstanceCardImgNotAvailable.vue";

export default {
  components: {
    SiteDetailsCard,
    InstallationDetailsCard,
    InstanceCardImgNotAvailable,
  },
  props: {
    instance: Object,
  },
  data: function () {
    return {
      fetching: true,
      fetchingInstallDetails: true,
      installationDetails: {},
      applicationId: this.$route.params.id,
    };
  },
  methods: {
    updateSiteName: function (data) {
      if (
        this.$refs.siteDetailsCard.$refs.siteNameHeader.$refs.form.validate()
      ) {
        this.$store.dispatch("updateSiteName", {
          instance: this.instance,
          newName: data,
        });
      }
    },
    fetchInstallationDetails() {
      this.fetchingInstallDetails = true;
      Api.get(`/instances/${this.applicationId}/installation-details`)
        .then((response) => {
          this.installationDetails = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingInstallDetails = false;
        });
    },
  },
  mounted() {
    this.fetching = false;
    this.fetchInstallationDetails();
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader__image {
    min-height: 100%;
  }
}
</style>